import Pristine from 'pristinejs';

class FormValidation {
  constructor(el) {
    this.el = el;
    this.init();
  }

  init() {
    // create the pristine instance
    this.pristine = new Pristine(this.el, {
      // class of the parent element where the error/success class is added
      classTo: 'form-group',
      errorClass: 'has-error',
      successClass: 'has-success',
      // class of the parent element where error text element is appended
      errorTextParent: 'invalid-feedback',
      // type of element to create for the error text
      errorTextTag: 'p',
      // class of the error text element
      errorTextClass: 'error-msg'
    });

    this.onSubmit = this.onSubmit.bind(this)
    this.el.addEventListener('submit', this.onSubmit);
  }

  onSubmit(evt) {
    // check if the form is valid
    var valid = this.pristine.validate(); // returns true or false

    if (!valid) {
      evt.preventDefault();

      const errors = [...this.el.querySelectorAll('.has-error')];
      const nav = document.querySelector('.navigation');
      if (errors.length) {
        window.scrollTo({
          behavior: 'smooth',
          left: 0,
          top: (nav ? -1 * nav.clientHeight : 0) + errors[0].getBoundingClientRect().top + window.scrollY - 20
        });
      }
    }
  }
}

[...document.querySelectorAll('form.js-validate')].forEach((el) => new FormValidation(el));